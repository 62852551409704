import React from 'react'

export interface ParselIconProps
  extends Omit<React.ComponentPropsWithoutRef<'svg'>, 'fill' | 'height' | 'width'> {
  size?: number
}

const widthHeightRatio = 79 / 24

export const ParselIcon = React.forwardRef<SVGElement, ParselIconProps>(function ParselIcon(
  { size = 24, ...props },
  ref,
) {
  return (
    <svg fill="none" viewBox="0 0 79 24" height={size} width={size * widthHeightRatio}>
      <path
        fill="#4D2F9B"
        d="M75 19h4V0h-4v19Zm-43 0h4V5h-4v14ZM42 9h-5c.9-2.3 3-3.9 5-4v4Zm7.8 10h2c2.1 0 3.2-2 3.2-4.5l-4.7-5C49.3 8.2 49 7.6 49 7c0-1 .8-1.8 1.4-2h-1.8C46.4 5 45 6.4 45 9l5 5.4c1.2 1.3 1.5 1.9 1.5 2.6 0 .9-1 1.8-1.7 2ZM45 19v-4c2.3.7 3.9 2.4 4 4h-4ZM55 5h-4c.7 2.3 2.4 3.9 4 4V5ZM18.1 19H23c-1.6-.4-3-2.2-3-4v-.4c0-1.5 1.3-3.2 3-3.6h-3c-2.2 0-4 2-4 3v2l2.1 3ZM25 9c0-2.4-.8-3.4-1.5-4h3.1L29 8v11h-4V9Zm-3-4c-1 2.4-3 3.8-5 4V5h5Zm36 5.5V15l3 4h4c-1.6-.7-3-3.5-3-6.8v-.7c0-2.6 1.5-6 3-6.5h-2.3C60.1 5 58 8.4 58 10.5ZM69 5h-3.5c1 .4 2 2 2.5 4-.6.6-1.5 1.5-2 3h6V9l-3-4Zm-3 14c1-2.4 3-3.8 5-4v4h-5ZM0 24h4V5H0v19Zm8.2-5H6c1.6-.6 3-4 3-6.5V12c0-3.3-1.4-6.3-3-7h4l3 4v4.5c0 2-2 5.5-4.8 5.5Z"
      />
    </svg>
  )
})
