import getConfig from "next/config";

import type _nextConfig from "next.config";

import { isBrowser } from "./env";

type NextConfig = typeof _nextConfig;

type PublicRuntimeConfig = NextConfig["publicRuntimeConfig"];
type ServerRuntimeConfig = NextConfig["serverRuntimeConfig"];

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export function getPublicRuntimeConfig() {
  return publicRuntimeConfig as PublicRuntimeConfig;
}

export function getServerRuntimeConfig() {
  return serverRuntimeConfig as ServerRuntimeConfig;
}

export function getRuntimeConfig() {
  return isBrowser() ? getPublicRuntimeConfig() : getServerRuntimeConfig();
}
