import { SVG } from "./svg";
import type { SVGProps } from "./svg";

export function TellimerIcon(props: Omit<SVGProps, "fill">) {
  return (
    <SVG {...props} viewBox="0 0 29 32">
      <path
        fill="#B14882"
        fillRule="evenodd"
        d="m8.77 4.46 6.22 3.56L14 9.76 7.78 6.2l.99-1.74Z"
        clipRule="evenodd"
      />
      <path
        fill="#3DA1B8"
        fillRule="evenodd"
        d="m8.77 13.31-6.22 3.56-1-1.74 6.23-3.55.99 1.73Z"
        clipRule="evenodd"
      />
      <path
        fill="#00778F"
        fillRule="evenodd"
        d="m8.77 13.31-6.22 3.56-1-1.74 6.23-3.55.99 1.73Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFA265"
        fillRule="evenodd"
        d="M7.27 26.72v-7.16h2v7.16h-2Z"
        clipRule="evenodd"
      />
      <path
        fill="#95B1BE"
        fillRule="evenodd"
        d="m20.22 27.59-6.23-3.61 1-1.73 6.23 3.6-1 1.74Z"
        clipRule="evenodd"
      />
      <path
        fill="#00BEB1"
        fillRule="evenodd"
        d="m27.44 16.87-6.23 3.55-.99-1.73 6.22-3.56 1 1.74Z"
        clipRule="evenodd"
      />
      <path
        fill="#FA5E4A"
        fillRule="evenodd"
        d="M21.72 5.33v7.11h-2v-7.1h2Z"
        clipRule="evenodd"
      />
      <path
        fill="#111"
        d="M.5 16.89V15.1l1.56-.89 1.55.9v1.77l-1.55.89-1.56-.9Zm0-7.11V8l1.56-.89L3.6 8v1.78l-1.55.89-1.56-.9Zm6.22-3.56V4.44l1.56-.88 1.55.88v1.78l-1.55.9-1.56-.9Zm0 7.11v-1.77l1.56-.9 1.55.9v1.77l-1.55.9-1.56-.9Zm0 7.11v-1.77l1.56-.9 1.55.9v1.77l-1.55.9-1.56-.9Zm0 7.12v-1.78l1.56-.9 1.55.9v1.78l-1.55.88-1.56-.88ZM19.17 6.22V4.44l1.55-.88 1.56.88v1.78l-1.56.9-1.55-.9Zm0 7.11v-1.77l1.55-.9 1.56.9v1.77l-1.56.9-1.55-.9Zm0 7.11v-1.77l1.55-.9 1.56.9v1.77l-1.56.9-1.55-.9Zm0 7.12v-1.78l1.55-.9 1.56.9v1.78l-1.56.88-1.55-.88Zm6.22-17.78V8l1.55-.89L28.5 8v1.78l-1.56.89-1.55-.9Zm0 7.11V15.1l1.55-.89 1.56.9v1.77l-1.56.89-1.55-.9Zm0 7.11v-1.78l1.55-.89 1.56.9V24l-1.56.89L25.4 24ZM14.5 3.56l-1.56-.9V.9L14.5 0l1.56.89v1.78l-1.56.89Zm-1.56 6.22V8l1.56-.89 1.56.89v1.78l-1.56.89-1.56-.9Zm0 7.11V15.1l1.56-.89 1.56.9v1.77l-1.56.89-1.56-.9Zm1.56 8L12.94 24v-1.78l1.56-.89 1.56.9V24l-1.56.89Zm-1.56 6.22v-1.78l1.56-.89 1.56.9v1.77L14.5 32l-1.56-.89ZM.5 24v-1.78l1.56-.89 1.55.9V24l-1.55.89L.5 24Z"
      />
    </SVG>
  );
}
