import { SVG } from "./svg";
import type { SVGProps } from "./svg";

export function SocialLinkedInIcon(props: Omit<SVGProps, "fill">) {
  return (
    <SVG {...props} viewBox="0 0 25 24">
      <g clipPath="url(#a)">
        <path
          fill="#4D2F9B"
          d="M23.45 0H1.55C.95 0 .5.45.5 1.05V23.1c0 .45.45.9 1.05.9H23.6c.6 0 1.05-.45 1.05-1.05V1.05C24.5.45 24.05 0 23.45 0ZM7.55 20.4H4.1V9h3.6v11.4h-.15ZM5.9 7.5c-1.2 0-2.1-1.05-2.1-2.1 0-1.2.9-2.1 2.1-2.1 1.2 0 2.1.9 2.1 2.1-.15 1.05-1.05 2.1-2.1 2.1Zm15 12.9h-3.6v-5.55c0-1.35 0-3-1.8-3s-2.1 1.5-2.1 3v5.7H9.8V9h3.45v1.5c.45-.9 1.65-1.8 3.3-1.8 3.6 0 4.2 2.4 4.2 5.4v6.3h.15Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </SVG>
  );
}
