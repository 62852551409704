import { datadogRum } from "@datadog/browser-rum";

import { envVars } from "./const";

export function initRum() {
  if (!envVars.RUM_ENV) return;

  datadogRum.init({
    applicationId: "570c64ea-b1ed-41ca-8476-240f1c23cd6b",
    clientToken: "pub843fe5620f69f470fe18cdc36588842a",
    site: "datadoghq.com",
    service: "parsel-fe-v2",
    env: envVars.RUM_ENV,
    version: envVars.VERSION,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

export const track = (
  event: string,
  properties: { [key: string]: any },
  options: SegmentAnalytics.SegmentOpts = {},
) => {
  return window.analytics.track(event, properties, {
    ...options,
    integrations: {
      ...options.integrations,
      HubSpot: false,
    },
  });
};
