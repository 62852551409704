import { useRef, useState } from "react";

import { useRouter } from "next/router";

import { useEnhancedEffect } from "common/hooks/use-enhanced-effect";

// @ts-ignore
import { messages as en } from "./en/messages.po";
import { activateLocale, Catalog, loadCatalog } from "./utils";

const catalogs: { [key: string]: Catalog } = { en };

interface Translation {
  locale: string;
  catalog: Catalog | null;
}

export function useTranslations(locale: string) {
  const { locales: supportedLocales } = useRouter();

  const translationsLoadedRef = useRef(false);
  const [translations, setTranslations] = useState<Translation>({
    locale,
    catalog: catalogs[locale],
  });

  if (
    !translationsLoadedRef.current &&
    translations.locale &&
    translations.catalog
  ) {
    activateLocale(translations.locale, translations.catalog);
    translationsLoadedRef.current = true;
  }

  useEnhancedEffect(() => {
    if (!locale) return;

    if (locale !== translations.locale && supportedLocales.includes(locale)) {
      loadCatalog(locale).then((catalog) => {
        activateLocale(locale, catalog);
        setTranslations({ locale, catalog });
      });
    }
  }, [locale]);

  const loaded = !!translations.catalog;

  return loaded;
}
