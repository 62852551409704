function getTop(element: HTMLElement, start: number) {
  if (element.nodeName === "HTML") return -start;
  return element.getBoundingClientRect().top + start;
}

function easeInOutCubic(duration: number) {
  return duration < 0.5
    ? 4 * duration * duration * duration
    : (duration - 1) * (2 * duration - 2) * (2 * duration - 2) + 1;
}

function calculateScrollPosition(
  start: number,
  end: number,
  elapsed: number,
  duration: number,
) {
  if (elapsed > duration) {
    return end;
  }
  return start + (end - start) * easeInOutCubic(elapsed / duration);
}

function isElement(arg: HTMLElement | number): arg is HTMLElement {
  return typeof arg !== "number";
}

interface SmoothScrollOptions {
  context?: HTMLElement;
  duration?: number;
  offset?: number;
}

export async function smoothScrollAsync(
  element: number,
  options?: SmoothScrollOptions,
): Promise<void>;
export async function smoothScrollAsync(
  element: HTMLElement,
  options?: SmoothScrollOptions,
): Promise<void>;
export async function smoothScrollAsync(
  element: any,
  { context, duration = 500, offset = 0 }: SmoothScrollOptions = {},
) {
  return new Promise<void>((resolve) => {
    if (typeof window === "undefined") return;

    const start = context ? context.scrollTop : window.scrollY;
    let end = isElement(element)
      ? getTop(element, start) - offset
      : Math.floor(element) - offset;

    const clock = Date.now();

    const tick = () => {
      const elapsed = Date.now() - clock;
      const nextPosition = calculateScrollPosition(
        start,
        end,
        elapsed,
        duration,
      );
      if (context) {
        context.scrollTop = nextPosition;
      } else {
        window.scroll(0, nextPosition);
      }

      if (elapsed > duration) {
        resolve();
      } else {
        requestAnimationFrame(tick);
      }
    };

    tick();
  });
}

export function smoothScroll(
  y: number,
  callback: () => void,
  options?: SmoothScrollOptions,
): void;
export function smoothScroll(
  element: HTMLElement,
  callback: () => void,
  options?: SmoothScrollOptions,
): void;
export function smoothScroll(
  element: any,
  callback: () => void,
  options?: SmoothScrollOptions,
) {
  smoothScrollAsync(element, options).then(callback);
}
