import type { IncomingMessage } from "http";

import { createPublicClient } from "./client/public-client";

export interface ApolloClientContext {
  req?: IncomingMessage & {
    cookies: {
      [key: string]: string;
    };
  };
}

export function getApolloClient(ctx?: ApolloClientContext) {
  return createPublicClient();
}
