import { SVG } from "./svg";
import type { SVGProps } from "./svg";

export function SocialTwitterIcon(props: Omit<SVGProps, "fill">) {
  return (
    <SVG {...props} viewBox="0 0 25 24">
      <g clipPath="url(#a)">
        <path
          fill="#4D2F9B"
          d="M8.05 21.9c9.05 0 14-7.5 14-14v-.64A10.1 10.1 0 0 0 24.5 4.7c-.9.4-1.85.66-2.83.78a4.94 4.94 0 0 0 2.17-2.72c-.97.57-2.03.97-3.13 1.19a4.93 4.93 0 0 0-8.4 4.5A13.98 13.98 0 0 1 2.18 3.3 4.93 4.93 0 0 0 3.7 9.87a4.89 4.89 0 0 1-2.23-.61v.06a4.93 4.93 0 0 0 3.95 4.82 4.9 4.9 0 0 1-2.22.09 4.93 4.93 0 0 0 4.6 3.42 9.88 9.88 0 0 1-7.3 2.04 13.94 13.94 0 0 0 7.55 2.2"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </SVG>
  );
}
