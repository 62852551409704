import React from "react";

import { useRouter } from "next/router";

import { t } from "@lingui/macro";

import { Button } from "common/ui/button";

export function ErrorBox() {
  return (
    <div className="flex min-h-screen w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center px-8">
        <h1 className="mb-4 text-5xl font-normal leading-none text-gray-900">
          {t`Oops, we're having some issues`}
        </h1>
        <p className="mb-4 text-xl font-normal leading-7 text-gray-700">
          {t`Sorry, we're having some trouble loading your data.`}
        </p>
        <Button as="a" href="mailto:support@parsel.ai" variant="filled">
          {t`Contact support`}
        </Button>
      </div>
    </div>
  );
}
