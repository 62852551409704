import { t } from "@lingui/macro";

import { ParselIcon } from "common/icons/parsel";
import { SocialFacebookIcon } from "common/icons/social-facebook";
import { SocialLinkedInIcon } from "common/icons/social-linkedin";
import { SocialTwitterIcon } from "common/icons/social-twitter";
import { TellimerIcon } from "common/icons/tellimer";
import { Typography } from "common/ui/typography";

import { Link } from "./link";

const linksPrimary = [
  [
    { href: "#features", label: t`Features` },
    { href: "#how-it-works", label: t`How it works` },
    { href: "#use-cases", label: t`Use cases` },
  ],
  [
    { href: "#why-parsel", label: t`Why Parsel?` },
    { href: "#pricing", label: t`Pricing` },
  ],
  [{ href: "/blog", label: t`Blog` }],
];

const linksSecondary = [
  { href: "/legal/cookies", label: t`Cookies` },
  { href: "/legal/privacy", label: t`Privacy Policy` },
  { href: "/legal/terms", label: t`Terms of Use` },
];

const linksSocial = [
  { href: "https://www.facebook.com/ParselHQ", icon: SocialFacebookIcon },
  { href: "https://twitter.com/ParselHQ", icon: SocialTwitterIcon },
  {
    href: "https://www.linkedin.com/showcase/parsel-hq/",
    icon: SocialLinkedInIcon,
  },
];

export function Footer() {
  return (
    <footer className="my-10 grid max-w-[1140px] grid-cols-1 place-items-center gap-y-10 px-10 md:my-[104px] md:mx-auto md:grid-cols-3 md:gap-y-[88px] md:px-0">
      <div className="flex flex-col items-center md:col-span-1 md:place-self-start">
        <ParselIcon size={48} />
        <div className="mt-6 flex gap-6 md:w-full">
          {linksSocial.map(({ href, icon: Icon }, index) => (
            <a key={index} href={href}>
              <Icon size={24} />
            </a>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 place-self-stretch md:col-span-2 md:my-0 md:grid-cols-3 md:place-items-start md:gap-0 md:gap-x-8">
        {linksPrimary.map((column, index) => (
          <div
            className="flex flex-col items-center gap-2 md:items-start md:gap-1"
            key={index}
          >
            {column.map(({ href, label }) => (
              <Link
                key={href}
                href={href}
                className="text-xl leading-[44px] hover:text-gray-300 md:leading-7"
              >
                {label}
              </Link>
            ))}
          </div>
        ))}
      </div>
      <hr className="w-full border-gray-100 md:hidden" />
      <div className="flex flex-col items-center gap-6 md:col-span-3 md:flex-row md:justify-end md:gap-6 md:place-self-stretch">
        {linksSecondary.map(({ href, label }) => (
          <Link
            key={href}
            href={href}
            className="hover:text-gray-300 md:order-2"
          >
            {label}
          </Link>
        ))}
        <Typography className="mt-10 md:order-1 md:mt-0 md:mr-auto">{t`Copyright © 2022 Parsel`}</Typography>
        <div className="flex items-center gap-3 border-l-gray-100 md:order-3 md:border-l md:pl-6">
          <Typography>Powered by</Typography>
          <a href="https://tellimer.com">
            <TellimerIcon size={32} />
          </a>
        </div>
      </div>
    </footer>
  );
}
