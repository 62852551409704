import React, { useCallback, useContext, useState } from "react";

interface UiContextShape {
  navMenuOpen: boolean;
  toggleNavMenu: (open?: boolean) => void;
  dataCollectionPreferencesOpen: boolean;
  toggleDataCollectionPreferencesOpen: (open?: boolean) => void;
}

export const UiContext = React.createContext<UiContextShape>(null as any);

export function useUi() {
  const ctx = useContext(UiContext);
  if (!ctx) {
    throw new Error(`Cannot call useUi() outside UiContext`);
  }
  return ctx;
}

interface UiProviderProps {
  children: any;
}

export function UiProvider({ children }: UiProviderProps) {
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [dataCollectionPreferencesOpen, setDataCollectionPreferencesOpen] =
    useState(false);

  const toggleNavMenu = useCallback((open?: boolean) => {
    if (typeof open !== "undefined") {
      setNavMenuOpen(open);
    } else {
      setNavMenuOpen((prev) => !prev);
    }
  }, []);

  const toggleDataCollectionPreferencesOpen = useCallback((open?: boolean) => {
    if (typeof open !== "undefined") {
      setDataCollectionPreferencesOpen(open);
    } else {
      setDataCollectionPreferencesOpen((prev) => !prev);
    }
  }, []);

  return (
    <UiContext.Provider
      value={{
        navMenuOpen,
        dataCollectionPreferencesOpen,
        toggleNavMenu,
        toggleDataCollectionPreferencesOpen,
      }}
    >
      {children}
    </UiContext.Provider>
  );
}
