import React from "react";

export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export function SVG({
  size = 24,
  height,
  width,
  viewBox = "0 0 48 48",
  ...props
}: SVGProps) {
  return (
    <svg
      {...props}
      viewBox={viewBox}
      height={height ?? size}
      width={width ?? size}
    />
  );
}
