import React from "react";

import { ErrorBox } from "./error-box";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: null | string;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  state: ErrorBoundaryState = {
    hasError: false,
    error: null,
  };

  componentDidCatch(error: any, errorInfo: any) {
    console.error("> Unhandled rendering error", error);
    console.error("> Info:", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBox />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
