import { envVars } from "common/const";

interface MetaNameAttribute {
  content: string;
  name: string;
}

interface MetaPropertyAttribute {
  content: string;
  property: string;
}

export type UnkeyedMetaAttribute = MetaNameAttribute | MetaPropertyAttribute;

function isNameAttribute(
  attr: UnkeyedMetaAttribute,
): attr is MetaNameAttribute {
  return "name" in attr;
}

function getMetaAttrKey(attr: UnkeyedMetaAttribute) {
  return isNameAttribute(attr) ? attr.name : attr.property;
}

export type KeyedMetaAttribute = UnkeyedMetaAttribute & {
  key: string;
};

export type MetaAttribute = KeyedMetaAttribute | UnkeyedMetaAttribute;

function isKeyedMetaAttribute(meta: MetaAttribute): meta is KeyedMetaAttribute {
  return "key" in meta;
}

function getKeyedMetaAttribute(meta: MetaAttribute): KeyedMetaAttribute {
  if (isKeyedMetaAttribute(meta)) return meta;

  return {
    ...meta,
    key: getMetaAttrKey(meta),
  };
}

export function ensureKeyedMetaAttributes(meta: MetaAttribute[]) {
  return meta.map(getKeyedMetaAttribute);
}

const defaultMetaAttributes = [
  {
    property: "og:site_name",
    content: "Parsel",
  },
  {
    property: "og:image",
    content: `${envVars.CDN_URL}/og-image.png`,
  },
  {
    property: "twitter:card",
    content: "summary_large_image",
  },
  {
    property: "twitter:image",
    content: `${envVars.CDN_URL}/og-image.png`,
  },
  {
    name: "mobile-web-app-capable",
    content: "yes",
  },
  {
    name: "apple-mobile-web-app-title",
    content: "Parsel",
  },
  {
    name: "author",
    content: "Parsel",
  },
  {
    name: "description",
    content:
      "Effortless data parsing. Financial-grade accuracy. Transform the data in your PDFs into structured, editable tables in minutes with Parsel.",
  },
  {
    property: "og:title",
    content: "Parsel | Tabular data extraction for your PDFs",
  },
  {
    property: "og:description",
    content:
      "Effortless data parsing. Financial-grade accuracy. Transform the data in your PDFs into structured, editable tables in minutes with Parsel.",
  },
  {
    property: "og:type",
    content: "website",
  },
  {
    property: "og:url",
    content: envVars.BASE_URL,
  },
  {
    name: "twitter:title",
    content: "Parsel | Tabular data extraction for your PDFs",
  },
] as UnkeyedMetaAttribute[];

export function getMetaAttributes(
  attrs: UnkeyedMetaAttribute[] = [],
): KeyedMetaAttribute[] {
  const common = defaultMetaAttributes.concat();

  const isMatchingAttribute = (
    a: UnkeyedMetaAttribute,
    b: UnkeyedMetaAttribute,
  ) =>
    (isNameAttribute(a) && isNameAttribute(b) && a.name === b.name) ||
    (!isNameAttribute(a) && !isNameAttribute(b) && a.property === b.property) ||
    false;

  const filteredBaseAttributes = common.filter((baseAttr) => {
    const matchingAttr = attrs.find((attr) =>
      isMatchingAttribute(attr, baseAttr),
    );

    return !matchingAttr;
  });

  return ensureKeyedMetaAttributes(filteredBaseAttributes.concat(attrs));
}
