import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

import { envVars } from "common/const";

export function createPublicClient() {
  const authLink = new HttpLink({
    uri: `${envVars.PUBLIC_API_URL}/graphql`,
    headers: {
      "x-api-key": envVars.PUBLIC_API_KEY,
    },
  });

  return new ApolloClient({
    uri: `${envVars.PUBLIC_API_URL}/graphql`,
    cache: new InMemoryCache(),
    link: authLink,
  });
}

export const client = createPublicClient();
