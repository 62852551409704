import React from "react";

import { ApolloProvider } from "@apollo/client";
import { t } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";
import { LDProvider, ProviderConfig } from "launchdarkly-react-client-sdk";
import { i18n as linguiI18n } from "locale/i18n";
import { useTranslations } from "locale/use-translations";
import type { NextWebVitalsMetric } from "next/app";
import Head from "next/head";

import { initRum, track } from "common/analytics";
import ErrorBoundary from "common/components/error-boundary";
import { Footer } from "common/components/footer";
import { LoadingScreen } from "common/components/loading-screen";
import { envVars } from "common/const";
import { UiProvider } from "common/context/ui";
import { useEnhancedEffect } from "common/hooks/use-enhanced-effect";
import type { ParselAppPropsType } from "common/types/page";
import { getMetaAttributes, KeyedMetaAttribute } from "common/utils/meta";

import { getApolloClient } from "gql/apollo";

import "../styles/fonts/formular.css";
import "../styles/globals.css";

function onConversationsAPIReady() {
  if (!window.HubSpotConversations) return;
  window.HubSpotConversations.on("contactAssociated", (payload) => {
    track("hubspot-chat", {
      action: "contact associated",
      label: payload.message,
    });
  });
  window.HubSpotConversations.on("conversationStarted", (payload) => {
    track("hubspot-chat", {
      action: "conversation started",
      label: payload.conversation.conversationId,
    });
  });
}

const defaultPageTitle = t`Parsel | Tabular data extraction for your PDFs`;

const ldOptions: ProviderConfig["options"] = {
  bootstrap: "localStorage",
};

interface ParselAppProps extends ParselAppPropsType {
  metaAttributes: KeyedMetaAttribute[];
}

export default function ParselApp({
  Component,
  pageProps,
  router,
}: ParselAppProps) {
  useEnhancedEffect(() => {
    initRum();

    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }, []);

  const pageTitle = Component.title || defaultPageTitle;
  const metaAttributes = Component.metaAttributes
    ? getMetaAttributes(Component.metaAttributes)
    : getMetaAttributes();

  const locale = router.locale ?? "en";

  const translationsLoaded = useTranslations(locale);

  if (!translationsLoaded) {
    return <LoadingScreen />;
  }

  return (
    <I18nProvider i18n={linguiI18n}>
      <ErrorBoundary>
        <ApolloProvider client={getApolloClient()}>
          <LDProvider
            clientSideID={envVars.LD_CLIENT_ID}
            deferInitialization
            options={ldOptions}
          >
            <Head>
              {metaAttributes.map(({ key, ...attr }) => (
                <meta key={key} {...attr} />
              ))}
              <title>{pageTitle}</title>
              <link rel="icon" href="/favicon.ico" />
            </Head>
            <UiProvider>
              <Component {...pageProps} />
              <Footer />
            </UiProvider>
          </LDProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </I18nProvider>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  // dump metrics somewhere. datadog?
}
