import { en } from "make-plural/plurals";
import type { PluralCategory } from "make-plural/plurals";

import { i18n } from "./i18n";

type PluralConfig = (n: number | string, ord?: boolean) => PluralCategory;

export interface Catalog {
  [key: string]: string;
}

const plurals: { [key: string]: PluralConfig } = { en };

export async function loadCatalog(locale: string) {
  const messages = await import(`./${locale}/messages.po`).then(
    (mod) => mod.messages,
  );
  return messages as Catalog;
}

export function activateLocale(locale: string, catalog: Catalog) {
  i18n.loadLocaleData({
    [locale]: { plurals: plurals[locale] },
  });
  i18n.load(locale, catalog);
  i18n.activate(locale);
}
