import React from "react";

import clsx from "clsx";

import type {
  OverridableComponent,
  OverrideProps,
} from "common/types/components";

type SectionHeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type TypographyVariant =
  | `h${SectionHeadingLevel}`
  | "body"
  | "subtitle"
  | "button"
  | "overline";

interface TypographyTypeMap<
  Props = {},
  DefaultComponent extends React.ElementType = "span",
> {
  props: Props & {
    align?: "left" | "right" | "center";
    paragraph?: boolean;
    variant?: TypographyVariant;
    color?: "primary" | "default";
    gutterBottom?: boolean;
  };
  defaultComponent: DefaultComponent;
}

export type TypographyProps<
  DefaultComponent extends React.ElementType = TypographyTypeMap["defaultComponent"],
  Props = React.ComponentPropsWithRef<DefaultComponent>,
> = OverrideProps<TypographyTypeMap<Props, DefaultComponent>, DefaultComponent>;

type TypographyVariantMap = Partial<{
  [variant in TypographyVariant]: string;
}>;

const variantElementMap: TypographyVariantMap = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle: "small",
};

function getTypographyElementType(
  variant: TypographyVariant,
  paragraph?: boolean,
): React.ElementType {
  if (paragraph) return "p";
  return (variantElementMap[variant] || "span") as React.ElementType;
}

export const Typography: OverridableComponent<TypographyTypeMap> =
  React.forwardRef(function Typography(
    {
      align,
      as: Component,
      className,
      paragraph,
      color = "default",
      variant,
      gutterBottom = true,
      ...props
    },
    ref,
  ) {
    if (!Component) {
      Component = getTypographyElementType(variant || "body", paragraph);
    }

    return (
      <Component
        {...props}
        className={clsx(
          {
            "text-base": variant === "body",
            "text-right": align === "right",
            "text-center": align === "center",
            "text-accent-500": color === "primary",
            "text-4xl md:text-6xl": variant === "h1",
            "text-3xl md:text-5xl md:leading-[48px]": variant === "h2",
            "text-2xl": variant === "h3",
            "text-xl md:text-2xl": variant === "h4",
            "text-sm": variant === "button" || variant === "subtitle",
            "text-sm md:text-base": variant === "overline",
            "font-bold uppercase tracking-widest": variant === "overline",
            "mb-6 md:mb-8": variant === "h1" && gutterBottom,
            "mb-5": variant === "h2" && gutterBottom,
            "mb-3": ["h3", "h4"].includes(variant) && gutterBottom,
            "mb-6": paragraph && gutterBottom,
            "tracking-edge": paragraph,
          },
          className,
        )}
        ref={ref}
      />
    );
  });
