import { SVG } from "./svg";
import type { SVGProps } from "./svg";

export function SocialFacebookIcon(props: Omit<SVGProps, "fill">) {
  return (
    <SVG {...props} viewBox="0 0 25 24">
      <g clipPath="url(#a)">
        <path
          fill="#4D2F9B"
          fillRule="evenodd"
          d="M24.5 12a12 12 0 1 0-13.87 11.85v-8.38H7.58V12h3.05V9.36c0-3.01 1.79-4.67 4.53-4.67 1.31 0 2.68.23 2.68.23v2.95h-1.5c-1.5 0-1.96.93-1.96 1.88V12h3.32l-.53 3.47h-2.8v8.38A12 12 0 0 0 24.5 12Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </SVG>
  );
}
