import * as React from "react";

import clsx from "clsx";

import type { OverrideProps, OverridableComponent } from "../types/components";

export type ButtonVariant = "default" | "outlined" | "filled";
export type ButtonSize = "sm" | "md" | "lg" | "hero" | "custom";
export type ButtonColor = "primary";

export interface ButtonTypeMap<
  Props = {},
  DefaultComponent extends React.ElementType = "button",
> {
  props: Props & {
    color?: ButtonColor;
    variant?: ButtonVariant;
    size?: ButtonSize;
    fullWidth?: boolean;
    invert?: boolean;
  };
  defaultComponent: DefaultComponent;
}

export type ButtonProps<
  DefaultComponent extends React.ElementType = ButtonTypeMap["defaultComponent"],
  Props = React.ComponentPropsWithRef<DefaultComponent>,
> = OverrideProps<ButtonTypeMap<Props, DefaultComponent>, DefaultComponent>;

export const Button: OverridableComponent<ButtonTypeMap> = React.forwardRef(
  function Button(props, ref) {
    const {
      as: Component = "button",
      className,
      color = "primary",
      fullWidth,
      size = "sm",
      variant = "default",
      invert = false,
      ...restProps
    } = props;

    const isOutlined = variant === "outlined";
    const isFilled = variant === "filled";

    const isPrimary = color === "primary";

    return (
      <Component
        {...restProps}
        ref={ref}
        className={clsx(
          "inline-flex items-center justify-center rounded-sm leading-6",
          {
            "bg-accent-500 text-white hover:bg-opacity-90":
              isPrimary && isFilled && !invert,
            "border border-white bg-white text-accent-500 hover:bg-opacity-90":
              isPrimary && isFilled && invert,
            "bg-transparent text-accent-500": isPrimary && !isFilled && !invert,

            "hover:border-gray-400 hover:text-gray-400": isOutlined && invert,
            "hover:bg-gray-50": !isFilled && !invert,
            "border border-current": isOutlined,

            "block w-full": fullWidth,
            "inline-block md:w-auto": !fullWidth,

            "py-2 px-4 text-sm md:py-2 md:px-4 md:text-base": size === "sm",
            "py-3 px-6 text-base": size === "md",
            "py-3 px-8 text-lg": size === "lg",
            "px-12 text-sm font-medium md:h-auto md:px-6 md:py-5 md:text-xl md:font-normal":
              size === "hero",
            "py-2": size === "hero" && !isOutlined,
            "py-[7px]": size === "hero" && isOutlined,
          },
          className,
        )}
      />
    );
  },
);
