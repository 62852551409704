import { getRuntimeConfig } from "./utils/config";

const runtimeConfig = getRuntimeConfig();

export const envVars = {
  LD_CLIENT_ID: runtimeConfig.LD_CLIENT_ID,
  PUBLIC_API_URL: runtimeConfig.PARSEL_API_URL,
  PUBLIC_API_KEY: runtimeConfig.PARSEL_API_KEY,
  BASE_URL: runtimeConfig.PARSEL_BASE_URL,
  CDN_URL: runtimeConfig.PARSEL_CDN_URL,
  SEGMENT_KEY: runtimeConfig.SEGMENT_KEY,
  RUM_ENV: runtimeConfig.RUM_ENV,
  VERSION: runtimeConfig.VERSION,
};

export const consentStorageKey = "@@data-collection/consent";
