import React, { useCallback, useDebugValue, useMemo } from "react";

import NextLink from "next/link";
import { useRouter } from "next/router";

import { ElementProps } from "common/types/components";
import { smoothScrollAsync } from "common/utils/smooth-scroll";
import { urlFromHref } from "common/utils/url-from-href";

function getNavbarOffset(spacingOffset: number = 16) {
  const navbarEl = document.getElementById("navbar");
  return navbarEl ? navbarEl.clientHeight + spacingOffset : 0;
}

function useTargetId(href: string) {
  const targetId = useMemo(() => {
    const url = urlFromHref(href);
    return url.hash ? url.hash.slice(1) : null;
  }, [href]);
  useDebugValue(targetId);
  return targetId;
}

export interface LinkProps extends Omit<ElementProps<"a">, "href"> {
  href: string;
  scrollContainer?: HTMLElement;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link({ href, onClick, scrollContainer, ...props }, ref) {
    const { push } = useRouter();
    const targetId = useTargetId(href);

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
      async (event) => {
        if (onClick) {
          onClick(event);
        }

        if (!targetId) return;

        event.preventDefault();

        const scrollX = scrollContainer
          ? scrollContainer.scrollLeft
          : window.scrollX;
        const scrollY = scrollContainer
          ? scrollContainer.scrollTop
          : window.scrollY;

        const targetEl = document.getElementById(targetId);

        push(href);
        window.scrollTo(scrollX, scrollY);
        await smoothScrollAsync(targetEl, {
          duration: 500,
          context: scrollContainer,
          offset: getNavbarOffset(),
        });
      },
      [href, push, scrollContainer, onClick, targetId],
    );

    return (
      <NextLink href={href} passHref>
        <a {...props} onClick={handleClick} ref={ref} />
      </NextLink>
    );
  },
);
